import React, {useState} from 'react'
import logo from '../../assets/img/logo.png';



const Navigation = () => {
	const [nav, setNav] = useState(false)

	const changeBg = () => {
		if (window.scrollY >= 80) {
		setNav(true)
		} else {
		setNav(false)
		}
	}

  window.addEventListener('scroll', changeBg)

    return (
        <div>
            <nav class= {nav ? 'navbar navbar-expand-lg navbar-dark fixed-top navbar-shrink' : 'navbar navbar-expand-lg navbar-dark fixed-top'} id="mainNav">
                <div class="container">
                    <div className='logo'>
                        <img src={logo} alt="."/>
                        <div class="divider"></div>
                        <h1 className='quote'>A SPARKLE OF MAGIC<br></br>IN EVERY EVENTS</h1>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu
                        <i class="fas fa-bars ms-1"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarResponsive">
                        <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                            <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                            <li class="nav-item"><a class="nav-link" href="#portfolio">Gallery</a></li>
                            <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                            <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>
                            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navigation;
