import one from "../../../assets/img/paskuhan/IMG_6101.JPG"
import two from "../../../assets/img/paskuhan/IMG_6114.JPG"
import three from "../../../assets/img/paskuhan/IMG_6270.JPG"
import four from "../../../assets/img/paskuhan/IMG_6287.JPG"
import five from "../../../assets/img/paskuhan/IMG_6372.JPG"
import six from "../../../assets/img/paskuhan/IMG_6473.JPG"
import seven from "../../../assets/img/paskuhan/IMG_7027.JPG"
import eight from "../../../assets/img/paskuhan/IMG_7204.JPG"
import nine from "../../../assets/img/paskuhan/IMG_7286.JPG"
import ten from "../../../assets/img/paskuhan/IMG_7561.JPG"

export const images3 = [
    {
        original: one,
        thumbnail: one
    },
    {
        original: two,
        thumbnail: two
    },
    {
        original: three,
        thumbnail: three
    },
    {
        original: four,
        thumbnail: four
    },
    {
        original: five,
        thumbnail: five
    },
    {
        original: six,
        thumbnail: six
    },
    {
        original: seven,
        thumbnail: seven
    },
    {
        original: eight,
        thumbnail: eight
    },
    {
        original: nine,
        thumbnail: nine
    },
    {
        original: ten,
        thumbnail: ten
    },
]


export const customImages = [
    {
        id: 1,
        imgSrc: one
    },
    {
        id: 2,
        imgSrc: two
    },
    {
        id: 3,
        imgSrc: three
    },
    {
        id: 4,
        imgSrc: four
    },
    {
        id: 5,
        imgSrc: five
    },
]