import one from '../../../assets/img/lim/272879233_4873833362705068_722353212531152169_n.jpg';
import two from '../../../assets/img/lim/273052790_4873830492705355_5452468706274944376_n.jpg';
import three from '../../../assets/img/lim/272897426_4873832972705107_1533875861491654556_n.jpg';
import four from '../../../assets/img/lim/272978006_4873830349372036_7234155416622308329_n.jpg';
import five from '../../../assets/img/lim/272988696_4873836579371413_2494983351010007064_n.jpg';
import six from '../../../assets/img/lim/cover photo.jpg';

export const images9 = [
	{
		original: one,
		thumbnail: one,
	},
	{
		original: two,
		thumbnail: two,
	},
	{
		original: three,
		thumbnail: three,
	},
	{
		original: four,
		thumbnail: four,
	},
	{
		original: five,
		thumbnail: five,
	},
	{
		original: six,
		thumbnail: six,
	},
];

export const customImages = [
	{
		id: 1,
		imgSrc: one,
	},
	{
		id: 2,
		imgSrc: two,
	},
	{
		id: 3,
		imgSrc: three,
	},
	{
		id: 4,
		imgSrc: four,
	},
	{
		id: 5,
		imgSrc: five,
	},
	{
		id: 6,
		imgSrc: six,
	},
];
