import one from '../../../assets/img/kultura/67705157_2390662121022217_3898501111174135808_n.jpg';
import two from '../../../assets/img/kultura/67730237_2390643604357402_8977701500956442624_n.jpg';
import three from '../../../assets/img/kultura/67731325_2390624337692662_2098464078149189632_n.jpg';
import four from '../../../assets/img/kultura/67737225_2390626501025779_411650869902180352_n.jpg';
import five from '../../../assets/img/kultura/67767399_2390628584358904_7012461295374958592_n.jpg';
import six from '../../../assets/img/kultura/67774726_2390623417692754_2243742021246255104_n.jpg';
import seven from '../../../assets/img/kultura/67778463_2390629681025461_8897981449039249408_n.jpg';
import eight from '../../../assets/img/kultura/68263792_2390630754358687_4688412241916067840_n.jpg';
import nine from '../../../assets/img/kultura/68623799_2390633511025078_3470977326341160960_n.jpg';
import ten from '../../../assets/img/kultura/cover photo.jpg';

export const images8 = [
	{
		original: one,
		thumbnail: one,
	},
	{
		original: two,
		thumbnail: two,
	},
	{
		original: three,
		thumbnail: three,
	},
	{
		original: four,
		thumbnail: four,
	},
	{
		original: five,
		thumbnail: five,
	},
	{
		original: six,
		thumbnail: six,
	},
	{
		original: seven,
		thumbnail: seven,
	},
	{
		original: eight,
		thumbnail: eight,
	},
	{
		original: nine,
		thumbnail: nine,
	},
	{
		original: ten,
		thumbnail: ten,
	},
];

export const customImages = [
	{
		id: 1,
		imgSrc: one,
	},
	{
		id: 2,
		imgSrc: two,
	},
	{
		id: 3,
		imgSrc: three,
	},
	{
		id: 4,
		imgSrc: four,
	},
	{
		id: 5,
		imgSrc: five,
	},
	{
		id: 6,
		imgSrc: six,
	},
	{
		id: 7,
		imgSrc: seven,
	},
	{
		id: 8,
		imgSrc: eight,
	},
	{
		id: 9,
		imgSrc: nine,
	},
	{
		id: 10,
		imgSrc: ten,
	},
];
