import React from 'react';
import one from '../../assets/img/concert/WMP09211.JPG';
import two from '../../assets/img/debut/DRV_4775.JPG';
import three from '../../assets/img/paskuhan/IMG_7027.JPG';
import four from '../../assets/img/gottalent/DSCF8208-min.JPG';
import five from '../../assets/img/120aniv/120th Philippine Navy Anniversary20180601_0079-min.JPG';
import six from '../../assets/img/acamm/cover-photo-min.jpg';
import seven from '../../assets/img/juanlife/cover photo-min.jpg';
import eight from '../../assets/img/kultura/cover photo.jpg';
import nine from '../../assets/img/lim/cover photo.jpg';

export default function PortfolioGrid() {
	return (
		<div>
			<section class="page-section bg-light" id="portfolio">
				<div class="container">
					<div class="text-center">
						<h2 class="section-heading text-uppercase">Gallery</h2>
						<h3 class="section-subheading text-muted">
							A photograph keeps a moment from running away.
						</h3>
					</div>
					<div class="row">
						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal1"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={one}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										125th Army Peace Concert
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal2"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={two}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										Harriett turns 18
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal3"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={three}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										Paskuhan sa Kampo
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal4"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={four}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										ARMY Got Talent!
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal5"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={five}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										The Philippine Navy Ball
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal6"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={six}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										ACAMM
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal7"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={seven}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										JUANLIFE Product Launch
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal8"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={eight}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										Gabi ng Kultura
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 col-sm-6 mb-4">
							<div class="portfolio-item">
								<a
									class="portfolio-link"
									data-bs-toggle="modal"
									href="#portfolioModal9"
								>
									<div class="portfolio-hover"></div>
									<img
										class="img-fluid"
										src={nine}
										alt="..."
									/>
								</a>
								<div class="portfolio-caption">
									<div class="portfolio-caption-heading">
										Lim + Santos Wedding
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
