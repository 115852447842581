import React from 'react'
import './style.css'

export default function Video() {
    return (
        <div className='video-container'>
            <div class='video'>
                <iframe src='https://www.youtube.com/embed/Co6oPEdn86k'></iframe>
            </div>
        </div>
    )
}