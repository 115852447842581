import React, { useRef,useState } from 'react'
import emailjs from '@emailjs/browser';

export default function Contact() {

        const [disableButton, setDisableButton] = useState(false);
        const [emailSuccess, setEmailSuccess] = useState(false);

        const form = useRef();
        
        const sendEmail = (e) => {
          e.preventDefault(); // prevents the page from reloading when you hit “Send”
          setDisableButton(true);
        
          emailjs.sendForm('service_rsrualh', 'template_7j7a9bq', form.current, 'ClkIYHEaV-75W9Gj4')
            .then((result) => {
                setEmailSuccess(true);
                console.log(result);
            }, (error) => {
                console.log(error)
            });
        };

    
    return (
            <div>
                <section class="page-section" id="contact">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-heading text-uppercase">Contact Us</h2>
                            {/* <h3 class="section-subheading text-muted"> </h3> */}
                        </div>
                        <form id="contactForm" ref={form} onSubmit={sendEmail}>
                            <div class="row align-items-stretch mb-5">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input class="form-control" id="name" type="text" placeholder="Your Name *" data-sb-validations="required" name='from_name'/>
                                        <div class="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
                                    </div>
                                    <div class="form-group">
                                        <input class="form-control" id="email" type="email" placeholder="Your Email *" data-sb-validations="required,email" name='email_add'/>
                                        <div class="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
                                        <div class="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
                                    </div>
                                    <div class="form-group mb-md-0">
                                        <input class="form-control" id="phone" type="tel" placeholder="Your Phone *" data-sb-validations="required" name ='phone'/>
                                        <div class="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group form-group-textarea mb-md-0">
                                        <textarea class="form-control" id="message" placeholder="Your Message *" data-sb-validations="required" name ='message'></textarea>
                                        <div class="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
                                    </div>
                                </div>
                            </div>
                            {emailSuccess && <div class="text-center text-success mb-3">
                                    <div class="fw-bolder">Email submitted successfully!</div>
                                    {/* <br />
                                    <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a> */}
                                </div>}
                            <div class="d-none" id="submitErrorMessage"><div class="text-center text-danger mb-3">Error sending message!</div></div>
                            {!emailSuccess &&<div class="text-center">
                                <button disabled={disableButton === true} class="btn btn-primary btn-xl text-uppercase" onClick={() => sendEmail()}>Send Message</button>
                            </div>}
                        </form>
                    </div>
                </section>
            </div>
    )
}