import React from 'react';
import ImageGallery from 'react-image-gallery';
import './index.css';
import './index.scss';
import firstImage from '../../../assets/img/close-icon.svg';
import { images } from '../constants/images';
import { images2 } from '../constants2/images';
import { images3 } from '../constants3/images';
import { images4 } from '../constants4/images';
import { images5 } from '../constants5/images';
import { images6 } from '../constants6/images';
import { images7 } from '../constants7/images';
import { images8 } from '../constants8/images';
import { images9 } from '../constants9/images';

export default function FirstModal() {
	return (
		<div>
			<div
				class="portfolio-modal modal fade"
				id="portfolioModal1"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											125th ARMY PEACE CONCERT
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images}
											showBullets={true}
										/>
										<p>
											The Philippine Army held a
											month-long celebration of its 125th
											Founding Anniversary anchored on the
											theme “Philippine Army @ 125:
											Kapayapaan Para Sa Lahat” with
											events lined up from March 1 to
											March 22, 2022, at its headquarters
											in Fort Bonifacio, Metro Manila.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												Philippine Army
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal2"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											Harriett turns 18!
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images2}
											showBullets={true}
										/>
										<p>
											There's no other way to celebrate
											Leyble's unica hija as she turns to
											a fine young lady. All the fairies
											of Imagik tossed their wands to make
											this night truly magical for
											everyone.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												Harriett Leyble
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal3"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											Paskuhan sa Kampo
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images3}
											showBullets={true}
										/>
										<p>
											Guess what? Philippine Navy takes
											the stage and showcased their
											unrivaled talents as they celebrate
											2022 Musicale-themed ‘Paskuhan’
											event.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												Philippine Navy
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal4"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											ARMY Got Talent!
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images4}
											showBullets={true}
										/>
										<p>
											The Philippine Army showcase their
											set of talents & success through
											their own version of “ARMY Got
											Talent! a night filled celebration
											of victory with a dash of our magic.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												Philippine Army
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal5"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											The Philippine Navy Ball
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images5}
											showBullets={true}
										/>
										<p>
											Here in IMAGIK, we make sure your
											milestones are not only remembered,
											but celebrated for lifetimes to
											come. The Philippine Navy celebrates
											120 years of pride, excellence, and
											success.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												Philippine Navy
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal6"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">ACAMM</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images6}
											showBullets={true}
										/>
										<p>
											The ASEAN Chiefs of Army
											Multicultural Meeting (ACAMM) held
											week long festivities that range
											from meetings, dinners, and tours
											that highlight the importance of
											military defense cooperation.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												ASEAN Chiefs of Army
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal7"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											JUANLIFE Product Launch
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images7}
											showBullets={true}
										/>
										<p>
											Big milestones for your company? We
											got you covered. Last October 28,
											2019, Juanlife launched its personal
											accident insurance.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												JUANLIFE
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal8"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											Gabi ng Kultura
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images8}
											showBullets={true}
										/>
										<p>
											The PAOLCI (Phil Army Officers
											Ladies Club Inc) held its annual
											cultural fashion show with the
											theme, “Hiyas ng Pamilya, Yaman ng
											Kultura”.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												Philippine Army Officers Ladies
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="portfolio-modal modal fade"
				id="portfolioModal9"
				tabindex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="close-modal" data-bs-dismiss="modal">
							<img src={firstImage} alt="Close modal" />
						</div>
						<div class="container">
							<div class="row justify-content-center">
								<div class="col-lg-8">
									<div class="modal-body">
										<h2 class="text-uppercase">
											Lim + Santos Wedding
										</h2>
										<p class="item-intro text-muted"></p>
										<ImageGallery
											items={images9}
											showBullets={true}
										/>
										<p>
											Celebrate your most beautiful moment
											in life with us.
										</p>
										<ul class="list-inline">
											<li>
												<strong>Client: </strong>
												Lim & Santos
											</li>
										</ul>
										<button
											class="btn btn-primary btn-xl text-uppercase"
											data-bs-dismiss="modal"
											type="button"
										>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
