import one from "../../../assets/img/gottalent/1MMP9049-min.JPG"
import two from "../../../assets/img/gottalent/1MMP9059-min.JPG"
import three from "../../../assets/img/gottalent/1MMP9068-min.JPG"
import four from "../../../assets/img/gottalent/DSCF8158-min.JPG"
import five from "../../../assets/img/gottalent/DSCF8188-min.JPG"
import six from "../../../assets/img/gottalent/DSCF8208-min.JPG"
import seven from "../../../assets/img/gottalent/DSCF8314-min.JPG"
import eight from "../../../assets/img/gottalent/DSCF8352-min.JPG"
import nine from "../../../assets/img/gottalent/DSCF8364-min.JPG"
import ten from "../../../assets/img/gottalent/DSCF8483-min.JPG"
import eleven from "../../../assets/img/gottalent/DSCF8740-min.JPG"

export const images4 = [
    {
        original: one,
        thumbnail: one
    },
    {
        original: two,
        thumbnail: two
    },
    {
        original: three,
        thumbnail: three
    },
    {
        original: four,
        thumbnail: four
    },
    {
        original: five,
        thumbnail: five
    },
    {
        original: six,
        thumbnail: six
    },
    {
        original: seven,
        thumbnail: seven
    },
    {
        original: eight,
        thumbnail: eight
    },
    {
        original: nine,
        thumbnail: nine
    },
    {
        original: ten,
        thumbnail: ten
    },
    {
        original: eleven,
        thumbnail: eleven
    },
]


export const customImages = [
    {
        id: 1,
        imgSrc: one
    },
    {
        id: 2,
        imgSrc: two
    },
    {
        id: 3,
        imgSrc: three
    },
    {
        id: 4,
        imgSrc: four
    },
    {
        id: 5,
        imgSrc: five
    },
]