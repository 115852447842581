// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Navigation from './components/Navigation/index';
import Masthead from './components/Masthead/index';
import Services from './components/Services/index';
import PortfolioGrid from './components/PortfolioGrid/index';
import About from './components/About/index';
import Team from './components/Team/index';
import Contact from './components/Contact/index';
import Footer from './components/Footer/index';
import FirstModal from './components/PortfolioModals/FirstModal';
import Quote from './components/Quote/index';
import Video from './components/Video/index';

function App() {
	return (
		<div>
			<Navigation />
			<Masthead/>
			<Services/>
			<Video/>
			<PortfolioGrid/>
			<About/>
			<Team/>
			<Quote/>
			<Contact/>
			<Footer/>
			<FirstModal/>
		</div>
	);
}

export default App;
