import React from 'react'

export default function Masthead() {
      
    return (
        <div>
            <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel">
                {/* <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol> */}
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <header class="masthead">   
                            <div class="container">
                                <div class="masthead-heading">Chris Tsuper <br></br>Weds Doktora!</div>
                                {/* <div class="masthead-subheading">Wedding Event</div> */}
                            </div>
                            {/* <a class="btn btn-primary btn-xl text-uppercase" href="#services">Get Started</a> */}
                        </header>
                    </div>
                    <div class="carousel-item">
                        <header class="masthead-2">   
                            <div class="container">
                                <div class="masthead-heading">Chris Tsuper <br></br>Weds Doktora!</div>
                                {/* <div class="masthead-subheading">Wedding Event</div> */}
                            </div>
                                {/* <a class="btn btn-primary btn-xl text-uppercase" href="#services">Get Started</a> */}
                        </header>
                    </div>
                    <div class="carousel-item">
                        <header class="masthead-3">   
                            <div class="container">
                                <div class="masthead-heading">Chris Tsuper <br></br>Weds Doktora!</div>
                                {/* <div class="masthead-subheading">Wedding Event</div> */}
                            </div>
                                {/* <a class="btn btn-primary btn-xl text-uppercase" href="#services">Get Started</a> */}
                        </header>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    )
}
