import one from "../../../assets/img/concert/WMP08042.JPG"
import two from "../../../assets/img/concert/WMP08061.JPG"
import three from "../../../assets/img/concert/WMP08086.JPG"
import four from "../../../assets/img/concert/WMP08114.JPG"
import five from "../../../assets/img/concert/WMP08199.JPG"
import six from "../../../assets/img/concert/WMP08241.JPG"
import seven from "../../../assets/img/concert/WMP08300.JPG"
import eight from "../../../assets/img/concert/WMP08387.JPG"
import nine from "../../../assets/img/concert/WMP08439.JPG"
import ten from "../../../assets/img/concert/WMP09211.JPG"

export const images = [
    {
        original: one,
        thumbnail: one
    },
    {
        original: two,
        thumbnail: two
    },
    {
        original: three,
        thumbnail: three
    },
    {
        original: four,
        thumbnail: four
    },
    {
        original: five,
        thumbnail: five
    },
    {
        original: six,
        thumbnail: six
    },
    {
        original: seven,
        thumbnail: seven
    },
    {
        original: eight,
        thumbnail: eight
    },
    {
        original: nine,
        thumbnail: nine
    },
    {
        original: ten,
        thumbnail: ten
    },
]


export const customImages = [
    {
        id: 1,
        imgSrc: one
    },
    {
        id: 2,
        imgSrc: two
    },
    {
        id: 3,
        imgSrc: three
    },
    {
        id: 4,
        imgSrc: four
    },
    {
        id: 5,
        imgSrc: five
    },
]