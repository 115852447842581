import React from 'react'
import one from '../../assets/img/about/1.jpg'
import two from '../../assets/img/about/2.jpg'
import three from '../../assets/img/about/3.jpg'
import four from '../../assets/img/about/4.jpg'

export default function About() {
    return (
        <div>
            <section class="page-section" id="about">
                <div class="container">
                    <div class="text-center">
                        <h2 class="section-heading text-uppercase">About</h2>
                        <h3 class="section-subheading text-muted"></h3>
                    </div>
                    <ul class="timeline">
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src={one} alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">OUR HUMBLE BEGINNINGS</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">Established in January 2006, Imagik Events Management Services has served weddings, debuts, corporate events, gatherings, and various other occasions by providing dedicated service in the planning and managing of their events and additional support in every way possible.</p></div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src={two} alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">WHO WE ARE</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">Imagik Events Management Services is an events company that presents a fresh way to create, engage and manage events. A wealth of experience in helping couples, debutantes, companies, and individuals with the best forms of event management is demonstrated with every endeavor. We strive to provide a service above and beyond for every event and for all clients.</p></div>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src={three} alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">WHAT WE'RE ABOUT</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">We do everything from developing creative concepts for an event to its planning, preparation, and execution right through to the packaged end product. Each detail in events management is meticulously attended to with care and handled with much importance. Nothing is left to chance; everything is done under the preference and taste of the client.</p></div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image"><img class="rounded-circle img-fluid" src={four} alt="..." /></div>
                            <div class="timeline-panel">
                                <div class="timeline-heading">
                                    <h4 class="subheading">WHAT WE DO</h4>
                                </div>
                                <div class="timeline-body"><p class="text-muted">We create an event worth remembering, from event conceptualization to execution. We are here to help your idea, concept, and dream event a reality, develop it and give it a touch of magic that makes each moment a memory to be cherished for all time.</p></div>
                            </div>
                        </li>
                        <li class="timeline-inverted">
                            <div class="timeline-image">
                                <h4>
                                    Be Part
                                    <br />
                                    Of Our
                                    <br />
                                    Story!
                                </h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}