import one from "../../../assets/img/120aniv/120th Philippine Navy Anniversary20180601_0001-min.JPG"
import two from "../../../assets/img/120aniv/120th Philippine Navy Anniversary20180601_0003-min.JPG"
import three from "../../../assets/img/120aniv/120th Philippine Navy Anniversary20180601_0041-min.JPG"
import four from "../../../assets/img/120aniv/120th Philippine Navy Anniversary20180601_0062-min.JPG"
import five from "../../../assets/img/120aniv/120th Philippine Navy Anniversary20180601_0079-min.JPG"
import six from "../../../assets/img/120aniv/120th Philippine Navy Anniversary20180601_0220-min.JPG"

export const images5 = [
    {
        original: one,
        thumbnail: one
    },
    {
        original: two,
        thumbnail: two
    },
    {
        original: three,
        thumbnail: three
    },
    {
        original: four,
        thumbnail: four
    },
    {
        original: five,
        thumbnail: five
    },
    {
        original: six,
        thumbnail: six
    },
]


export const customImages = [
    {
        id: 1,
        imgSrc: one
    },
    {
        id: 2,
        imgSrc: two
    },
    {
        id: 3,
        imgSrc: three
    },
    {
        id: 4,
        imgSrc: four
    },
    {
        id: 5,
        imgSrc: five
    },
]