import one from '../../../assets/img/acamm/901A1719-min.JPG';
import two from '../../../assets/img/acamm/901A1791-min.JPG';
import three from '../../../assets/img/acamm/901A1841-min.JPG';
import four from '../../../assets/img/acamm/901A1854-min.JPG';
import five from '../../../assets/img/acamm/cover-photo-min.jpg';
import six from '../../../assets/img/acamm/IMG_1034-min.JPG';
import seven from '../../../assets/img/acamm/IMG_7611-min.JPG';
import eight from '../../../assets/img/acamm/IMG_1237-min.JPG';
import nine from '../../../assets/img/acamm/IMG_1319-min.JPG';
import ten from '../../../assets/img/acamm/IMG_1501-min.JPG';
import eleven from '../../../assets/img/acamm/IMG_7305-min.JPG';

export const images6 = [
	{
		original: one,
		thumbnail: one,
	},
	{
		original: two,
		thumbnail: two,
	},
	{
		original: three,
		thumbnail: three,
	},
	{
		original: four,
		thumbnail: four,
	},
	{
		original: five,
		thumbnail: five,
	},
	{
		original: six,
		thumbnail: six,
	},
	{
		original: seven,
		thumbnail: seven,
	},
	{
		original: eight,
		thumbnail: eight,
	},
	{
		original: nine,
		thumbnail: nine,
	},
	{
		original: ten,
		thumbnail: ten,
	},
	{
		original: eleven,
		thumbnail: eleven,
	},
];

export const customImages = [
	{
		id: 1,
		imgSrc: one,
	},
	{
		id: 2,
		imgSrc: two,
	},
	{
		id: 3,
		imgSrc: three,
	},
	{
		id: 4,
		imgSrc: four,
	},
	{
		id: 5,
		imgSrc: five,
	},
	{
		id: 6,
		imgSrc: six,
	},
	{
		id: 7,
		imgSrc: seven,
	},
	{
		id: 8,
		imgSrc: eight,
	},
	{
		id: 9,
		imgSrc: nine,
	},
	{
		id: 10,
		imgSrc: ten,
	},
	{
		id: 11,
		imgSrc: eleven,
	},
];
