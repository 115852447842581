import one from "../../../assets/img/debut/DRV_4553.JPG"
import two from "../../../assets/img/debut/DRV_4775.JPG"
import three from "../../../assets/img/debut/DRV_4857.JPG"
import four from "../../../assets/img/debut/DRV_4888.JPG"
import five from "../../../assets/img/debut/DRV_4925.JPG"
import six from "../../../assets/img/debut/DRV_4990.JPG"
import seven from "../../../assets/img/debut/DRV_4997.JPG"
import eight from "../../../assets/img/debut/DRV_5193.JPG"
import nine from "../../../assets/img/debut/DRV_5523.JPG"
import ten from "../../../assets/img/debut/DRV_5541.JPG"

export const images2 = [
    {
        original: one,
        thumbnail: one
    },
    {
        original: two,
        thumbnail: two
    },
    {
        original: three,
        thumbnail: three
    },
    {
        original: four,
        thumbnail: four
    },
    {
        original: five,
        thumbnail: five
    },
    {
        original: six,
        thumbnail: six
    },
    {
        original: seven,
        thumbnail: seven
    },
    {
        original: eight,
        thumbnail: eight
    },
    {
        original: nine,
        thumbnail: nine
    },
    {
        original: ten,
        thumbnail: ten
    },
]


export const customImages = [
    {
        id: 1,
        imgSrc: one
    },
    {
        id: 2,
        imgSrc: two
    },
    {
        id: 3,
        imgSrc: three
    },
    {
        id: 4,
        imgSrc: four
    },
    {
        id: 5,
        imgSrc: five
    },
]